<template>
  <div class="eshop-home">
    <div class="eshop-containner">
      <div class="eshop" @click="ecodeDetail(product.id)" v-for="(product, idx) in products">
        <div class="cover">
          <img :src="staticUrl + product.cover"/>
        </div>
        <div class="info">
          <div class="name">{{ product.name }}</div>
          <div class="price">
            <span class="label">￥</span
            >{{ Number(product.price / 100).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
    <loading :display="showLoading"></loading>
  </div>
</template>
<script>

import { getProducts } from "@/api/eshop"
import Loading from "@/components/Loading"
import { reportError } from "@/utils/log"
import { utils } from '@/utils'

export default {
  name: "Home",
  data() {
    return {
      showLoading: false,
      products: [],
      staticUrl: utils.getStaticUrl() + 'eshop/'
    }
  },
  components: {
    loading: Loading
  },
  methods: {
    async getProducts () {
      try {
        this.showLoading = true
        let res = await getProducts()
        this.showLoading = false
        if (res.data.code == 0) {
          this.products = res.data.products
        }
      } catch (error) {
        reportError(error)
      }
    },
    ecodeDetail(id) {
      this.$router.push(`/eshop/detail?pid=${id}`)
    }
  },
  created() {
    this.getProducts()
  },
}
</script>

<style lang="less" scoped>
.eshop-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 7.5rem;
  flex-shrink: 0;
  height: 100%;
  font-family: PingFangSC-Medium, PingFang SC;
  .eshop-containner {
    width: 7.3rem;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 0.48rem;
    .eshop {
      cursor: pointer;
      width: 3.6rem;
      height: 4.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 0.12rem;
      box-shadow: 0px 0px 0.08rem 0.04rem rgba(0, 0, 0, 0.06);
      border-radius: 0.06rem;
      .cover {
        width: 3.6rem;
        height: 3.6rem;
        background: #deebe9;
        border-radius: 0.06rem 0.06rem 0px 0px;
        img {
          width: 3.6rem;
          height: 3.6rem;
        }
      }
      .info {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 0.24rem;
        .name {
          font-size: 0.28rem;
          color: #333333;
        }
        .price {
          .label {
            font-size: 0.24rem;
            font-weight: 400;
            color: #000102;
            margin-right: 0.1rem;
          }
          color: #000102;
          font-weight: 500;
          font-size: 0.32rem;
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7.5rem;
    background: #2e2e31;
    font-size: 0.24rem;
    color: white;
    .about-us {
      width: 7.02rem;
      padding-top: 0.26rem;
      .desc {
        margin-top: 0.12rem;
      }
    }
    .contact-us {
      margin-top: 0.34rem;
      width: 7.02rem;
      display: flex;
      justify-content: space-between;
      .wechat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: 1.74rem;
          width: 1.74rem;
        }
      }
      .ways {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 1.74rem;
        width: 4.84rem;
      }
    }
  }
}
</style>
